import React from 'react';
import { Heading, Box, Divider, theme, Grid, Flex, TextStyled } from '../../design-system';
import DefaultButton from '../DefaultButton';

import { formatCurrency, isFieldRendered } from '../../library/functions';

const ApplicationOverview = ({ onClick, form, formData, setFormStep, backStep, errorMessage, setErrorMessage, loading, extraSections }) => {
  const formatData = field => {
    const { parentKey, key } = field;
    const fieldParent = parentKey ? formData[parentKey] : null;
    const fieldValue = fieldParent ? fieldParent[key] : formData[key];
    const selectedOption = field.options ? field.options.find(option => option.value === fieldValue) : null;

    switch (field.type) {
      case 'currency':
        if (fieldValue) return formatCurrency(parseFloat(fieldValue), 'pounds');
        return formatCurrency(0, 'pounds');
      case 'select':
        return selectedOption ? selectedOption.label : null;
      case 'textfield':
        return fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
      case 'checkbox':
        return fieldValue ? 'Yes' : 'No';
      default:
        return fieldValue?.toString();
    }
  };

  const formatAddress = address => {
    const { flat, houseName, houseNumber, addressLine1, addressLine2, townCity, postcode } = address;

    const flatNum = flat || '';
    const name = houseName || '';
    const houseNum = houseNumber || '';
    const line1 = addressLine1 ? addressLine1.charAt(0).toUpperCase() + addressLine1.slice(1) : null;
    const line2 = addressLine2 ? addressLine2.charAt(0).toUpperCase() + addressLine2.slice(1) : null;
    const city = townCity ? townCity.charAt(0).toUpperCase() + townCity.slice(1) : null;

    return (
      <>
        {`${[flatNum, name].filter(Boolean).join(', ')}${flatNum || name ? ', ' : ''}${[houseNum, line1].filter(Boolean).join(' ')}, `}{' '}
        {line2 && `${line2}, `} {`${city}, ${postcode.toUpperCase()}`}
      </>
    );
  };

  const renderOverviewRow = (label, value, key) => {
    return (
      <>
        <Flex key={key} alignItems='center' justifyContent='space-between' my={2}>
          <TextStyled m={0} flex={1}>
            {label}
          </TextStyled>
          <TextStyled m={0} flex={1} textAlign='right'>
            {value}
          </TextStyled>
        </Flex>
      </>
    );
  };

  const renderField = (field, index) => {
    // Avoid rendering a field if it requires another field to have a particular value
    // and that criteria has not been met
    if (field.isRequired === false && field.type !== 'checkbox' && !formData[field.key]) return null;
    if (!isFieldRendered(field, formData)) return null;

    if (field.type === 'addressHistory' || field.type === 'employerHistory') {
      const addresses = formData[field.parentKey];

      return addresses.map(address => renderOverviewRow(address.title, formatAddress(address), address.id));
    }

    if (field.subFields) {
      const { subFields } = field;
      return subFields.map((subField, subFieldIndex) => renderField(subField, subFieldIndex));
    }

    return renderOverviewRow(field.label, formatData(field), index);
  };

  const handleEditButton = async section => {
    setErrorMessage('');
    await setFormStep(section);
    backStep();
  };

  return (
    <Box className='wrapper' p={3}>
      <Heading as='h3' color={theme.colors.scarletGum} my={3}>
        Application Overview
      </Heading>
      {form.sections.map(section => {
        let sectionFields = [];
        section.steps.forEach(step => {
          const { fields, dynamicFields } = step;
          let renderedFields = fields;
          if (dynamicFields) {
            const { dynamicSetKey, fieldSets } = dynamicFields;
            const dynamicFieldValue = formData[dynamicSetKey];
            renderedFields = fieldSets[dynamicFieldValue];
          }
          sectionFields = sectionFields.concat(renderedFields);
        });

        return (
          <Box key={section.sectionName}>
            <Grid gridGap={3}>
              <Box bg={theme.colors.lighGrey} border={`1px solid ${theme.colors.mediumGrey}`} borderColor='#DCDCDC' p={3} borderRadius={2} mb={4}>
                <Flex alignItems='center' justifyContent='space-between'>
                  <TextStyled fontSize={3} color={theme.colors.scarletGum} m={0}>
                    {section.title}
                  </TextStyled>
                  <DefaultButton px={4} text='Edit' bg={theme.colors.oliveDrab} onClick={() => handleEditButton(section)} />
                </Flex>
                <Divider my={3} bg={theme.colors.mediumGrey} height={theme.sizes.divider[0]} />
                {sectionFields.map((field, index) => {
                  return renderField(field, index);
                })}
              </Box>
            </Grid>
          </Box>
        );
      })}
      {form.name === 'New Collection Schedule' ? (
        <Flex my={[4, 4, 4, null]} mt={[null, null, '20px auto 0']}>
          <DefaultButton disabled text='Back' flex='1' bg='#E8E8E8' />
          <DefaultButton
            onClick={onClick}
            type='submit'
            text='Create Collection Schedule'
            bg={theme.colors.oliveDrab}
            flex='2'
            ml={3}
            loading={loading}
          />
        </Flex>
      ) : (
        <Flex my={[4, 4, 4, null]} mt={[null, null, '20px auto 0']}>
          <DefaultButton disabled text='Back' flex='1' bg='#E8E8E8' />
          <DefaultButton onClick={onClick} type='submit' text='Submit' bg={theme.colors.oliveDrab} flex='2' ml={3} loading={loading} />
        </Flex>
      )}
      <Box mt={4}>
        {errorMessage && (
          <TextStyled color='red' fontSize={2}>
            {errorMessage}
          </TextStyled>
        )}
      </Box>
    </Box>
  );
};
export default ApplicationOverview;
