import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from '@reach/router';

import ProfileMenu from './ProfileMenu';
import Notifications from './Notifications';
import Nav from './Nav';
import { useTheme } from 'emotion-theming';
import Banner from './Banner';

const breakpoints = [40, 52, 64, 80, 114, 160];
const mq = breakpoints.map(bp => `@media (min-width: ${bp}em)`);

const HeaderStyles = styled.header`
  position: relative;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .logo-container {
    img {
      width: auto;
      max-height: 65px;
      height: 65px;
    }
  }
  .menu-items {
    display: none;
    flex: 0;
    transition: flex 2s ease-in-out;
    margin: 0 -15px;
    ul {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;

      li {
        a {
          padding: 20px 0;
          display: block;
          position: relative;
          color: #818181;
          text-decoration: none;
          font-weight: bold;
          &.active {
            color: ${props => props.theme.colors.primary};
          }
        }
      }
    }

    &.is-active {
      display: flex;
    }
  }

  .user-menu {
    display: none;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid rgba(#fff, 0.1);

    ul {
      margin: 0;
      padding: 0 15px;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 0 0 100%;

        .upper {
          flex: 0 0 50px;
          position: relative;
          svg {
            font-size: 35px;
            line-height: 35px;
            position: relative;
            border-radius: 50%;
            color: ${props => props.theme.colors.primary};
          }
        }

        .user-name {
          @include font-size(2.5, 3);
          display: flex;
          flex-direction: column;
          margin: 0;
          flex: 1;

          span {
            @include font-size(1.6);
            opacity: 0.4;
          }
        }

        svg {
          font-size: 18px;
          transition: color 0.3s ease-in-out;
          margin-right: 70px;
          transition: transform 0.2s ease-in-out;
          margin: 0;
          margin-left: auto;

          &.fa-times {
            display: none;
          }
        }

        .inner-menu {
          transition: all 0.3s ease-in-out;
          display: flex;
          max-height: 0;
          overflow: hidden;
          margin-top: 10px;
          flex-direction: column;
          background: #f7f7f7;

          .top {
            display: none;
          }

          li {
            padding: 10px 0;

            a,
            .logout-button {
              color: #818181;
              display: flex;
              align-items: center;
              text-decoration: none;

              svg {
                margin-right: 10px;
                width: 25px;
                transform: none;
              }
            }
          }
        }

        &.is-active {
          .fa-caret-down {
            transform: rotate(180deg);
          }

          .inner-menu {
            max-height: 201px;
          }
        }
      }
    }

    &.is-active {
      display: flex;
    }
  }

  .notifications {
    align-self: flex-end;
    position: absolute;
    top: 30px;
    display: flex;
    align-items: center;

    a {
      position: relative;
      &.unread {
        &:after {
          content: '';
          display: block;
          height: 10px;
          width: 11px;
          background: red;
          border-radius: 50%;
          position: absolute;
          top: -1px;
          right: 15px;
        }
      }
    }

    svg {
      font-size: 25px;
      color: ${props => props.theme.colors.primary};
      margin-right: 20px;
    }

    .hamburger {
      top: 1px;
      position: relative;
    }
  }

  ${mq[2]} {
    display: grid;
    grid-template-areas: 'logo navigation notifications user';
    grid-template-columns: 191px 1fr 30px auto;
    grid-gap: 20px;
    align-items: center;

    .logo-container {
      max-width: 100px;
      justify-self: center;
    }

    .menu-items {
      display: flex;
      order: 2;
      flex: 1;
      margin: 0;
      background: #f7f7f7;
      padding: 23px;
      border-radius: 5px;
      margin-left: 70px;
      ul {
        display: flex;
        margin: 0;
        list-style: none;
        flex-direction: row;
        li {
          margin: 0;
          a {
            text-decoration: none;
            display: block;
            position: relative;
            color: #818181;
            font-weight: bold;
            padding: 0 20px;
            transition: opacity 0.2s ease-in-out;
            &.active {
              background: none;
              color: ${props => props.theme.colors.primary};
            }

            &:hover {
              text-decoration: none;
              opacity: 0.7;
            }
          }
        }
      }
    }

    .notifications {
      position: relative;
      align-self: auto;
      top: auto;
      .hamburger {
        display: none;
      }
    }

    .user-menu {
      display: flex;
      flex-direction: row;
      order: 4;
      align-self: auto;
      border-bottom: 0;
      margin: 0;
      margin-left: auto;
      min-width: 230px;

      ul {
        li {
          align-items: center;
          margin: 0;

          .upper {
            svg {
              max-width: 45px;
            }
          }

          .user-name {
            @include font-size(1.7);
            color: ${props => props.theme.colors.primary};
            margin: 0;
            margin: 0 20px;

            span {
              display: none;
            }
          }

          svg {
            color: ${props => props.theme.colors.primary};
            &.fa-times {
              display: block;
              cursor: pointer;
            }
          }

          .inner-menu {
            box-sizing: border-box;
            transition: all 0.4s;
            position: absolute;
            max-height: none;
            height: 100vh;
            right: -350px;
            top: 0;
            width: 350px;
            z-index: 22;
            overflow: visible;
            padding: 25px;
            cursor: default;

            .top {
              display: flex;
              align-items: flex-end;
              padding-bottom: 20px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              margin-bottom: 16px;

              img {
                max-width: 70px;
                border-radius: 50%;
                margin-right: 10px;
              }

              .user-name {
                @include font-size(2.5);
                margin: 0;
              }
            }

            li {
              display: block;
              flex: none;
              width: 100%;
              padding: 0;

              a,
              .logout-button {
                display: flex;
                width: 100%;
                align-self: center;
                padding: 10px 0;
                margin-bottom: 10px;
                text-decoration: none;
                border-radius: 5px;
                transition: opacity 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                  opacity: 0.5;
                }

                svg {
                  margin: 0;
                  margin-right: 8px;
                }
              }
            }

            &:after {
              content: ' ';
              position: absolute;
              right: 100%;
              height: 100%;
              width: 5px;
              top: 0;
              background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
              display: block;
            }
          }

          &.is-active {
            .inner-menu {
              max-height: none;
              right: 0px;
              -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
              transition: all 0.8s;
            }
          }
        }
      }
    }

    .notifications {
      order: 3;
      margin-left: auto;
    }
  }
`;

const Header = ({ currentUser, currentCustomer, logout, memberNamingConvention, isLegalEntity }) => {
  const theme = useTheme();
  let logo;
  try {
    if (theme.logoAuthenticated) logo = require(`../assets/${theme.logoAuthenticated}`);
    else logo = require(`../assets/${theme.logo}`);
  } catch (e) {
    console.log(e);
  }
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [bodyOverlay, setBodyOverlay] = useState(false);

  const userNotifications = {
    unreadCount: 0,
  };

  const hamburgerClick = e => {
    e.preventDefault();
    setHamburgerActive(!hamburgerActive);
    setActiveMenu(!activeMenu);
  };

  const hasParentWithMatchingSelector = (target, selector) => {
    return [...document.querySelectorAll(selector)].some(el => el !== target && el.contains(target));
  };

  const toggleSideMenu = () => {
    setBodyOverlay(!bodyOverlay);
    const bodyClass = document.body.classList;
    const checkBodyClick = e => {
      if (e.target.className === 'overlay') toggleSideMenu();
    };

    if (bodyClass.contains('overlay')) {
      setUserMenu(false);
      bodyClass.remove('overlay');
      document.body.removeEventListener('click', checkBodyClick);
    } else {
      setUserMenu(true);
      bodyClass.add('overlay');
      document.body.addEventListener('click', checkBodyClick);
    }
  };

  const userMenuActive = e => {
    e.preventDefault();

    if (!hasParentWithMatchingSelector(e.target, '.inner-menu') && e.target.className !== 'inner-menu') {
      toggleSideMenu();
    }
  };

  return (
    <>
      {theme.name === 'CWRT' && <Banner />}
      <HeaderStyles>
        <div className='logo-container'>
          <Link to='/dashboard'>
            <img src={`${currentUser.creditUnion.resourceUrl}/resources/cu_logo_1.png`} alt='Soar Dashboard' />
          </Link>
        </div>
        <ProfileMenu
          currentUser={currentUser.individualDetails}
          showMenu={userMenu}
          toggleSideMenu={toggleSideMenu}
          userMenuActive={userMenuActive}
          activeMenu={activeMenu}
          logout={logout}
          creditUnion={currentUser.creditUnion}
          memberNamingConvention={memberNamingConvention}
          isLegalEntity={isLegalEntity}
        />
        <Nav
          setActiveMenu={setActiveMenu}
          activeMenu={activeMenu}
          setHamburgerActive={setHamburgerActive}
          creditUnion={currentUser.creditUnion}
          isLegalEntity={isLegalEntity}
          customer={currentCustomer}
        />
        <Notifications
          hamburgerActive={hamburgerActive}
          hamburgerClick={hamburgerClick}
          setHamburgerActive={setHamburgerActive}
          userNotifications={userNotifications}
          currentUser={currentUser}
        />
      </HeaderStyles>
    </>
  );
};

export default Header;
